<template>
  <section :id="`${anchor ? anchor : 'block-' + indexBlock}`"
    class="block-carrers p-3 py-lg-5 px-lg-0"
    :class="getContainerBackground(componentData?.content_cta_multiple_container_background)"
	>
		<div  class="container">
			<div class="row">
				<div
					class="col-12 col-lg-5 order-2 d-flex"
					:class="componentData.content_cta_multiple_image_positioning == 'right' ? 'order-lg-1' : 'order-lg-2'"
				>
					<div class="text-part m-auto">
						<div 
							v-if="componentData.content_cta_multiple_context" 
							class="h4"
							:class="`text-${getScheme(componentData?.content_cta_multiple_color_scheme)}`"
							v-html="componentData.content_cta_multiple_context"
						>
						</div>

						<h2 
							v-if="componentData.content_cta_multiple_title"
							:class="`text-${getScheme(componentData?.content_cta_multiple_color_scheme)}`"
							v-html="componentData.content_cta_multiple_title"
						>
						</h2>
						<div 
							v-if="componentData.content_cta_multiple_text"
							class="mb-4"
							:class="`text-${getScheme(componentData?.content_cta_multiple_color_scheme)}`"
							v-html="componentData.content_cta_multiple_text"
						>
						</div>

						<template v-if="componentData?.content_cta_multiple_repeater?.length > 0" >
							<div 
								v-for="item in componentData.content_cta_multiple_repeater"
								class="my-3 me-lg-4"
							>
								<ButtonCta
									:target="item.content_cta_multiple_cta_target"
									:href="item.content_cta_multiple_cta_page_link? item.content_cta_multiple_cta_page_link : ''"
									:file="item.content_cta_multiple_cta_file? item.content_cta_multiple_cta_file : ''"
									:theme="getTheme(item?.content_cta_multiple_cta_theme)"
									:size="item.content_cta_multiple_cta_size"
									:label="item.content_cta_multiple_cta_label"
									:arrow="item.content_cta_multiple_add_arrow"
									:class="'w-100 w-sm-85'"
								/>  
							</div>
						</template>
					</div>
				</div>

				<div 
					class="col-12 col-lg-7 order-1 mb-3 mb-lg-0"
					:class="componentData.content_cta_multiple_image_positioning == 'right' ? 'order-lg-2' : 'order-lg-1'"
				>
					<figure v-if="componentData?.content_cta_multiple_image?.format?.large[0]" class="figure">
						<NuxtImg 
							v-if="componentData?.content_cta_multiple_image" 
							:src="imageDefault(componentData.content_cta_multiple_image)[0]"
							:width="imageDefault(componentData.content_cta_multiple_image)[1]"
							:height="imageDefault(componentData.content_cta_multiple_image)[2]"
							class="img-fluid rounded d-none d-lg-block"
							:alt="imageTitle(componentData.content_cta_multiple_image)"
							:size="imageSizes(componentData.content_cta_multiple_image)"
							loading="lazy"
						/>
					</figure>
				</div>
			</div>
    </div>
  </section>
</template>

<script setup>
	import { 
		imageDefault, 
		imageTitle, 
		imageSizes
	} from '../../../utils/image.js';

	const props = defineProps({
		data: Object,
		index: Number,
	});

	// data
	const componentData = props?.data?.data || {};
	const anchor = props?.data?.anchor || '';
	const indexBlock = props?.index || '1';
    
  const getScheme = (scheme) => {
    let text = '';
    switch (scheme) {
      case 'primary':
        text = 'primary';
        break;
      case 'secondary':
        text = 'secondary';
        break;
      case 'light-primary':
        text  = 'light-primary';
        break;
      case 'transparent':
        text  = 'white';
        break;
      case 'dark-gray':
        text  = 'corpo';
        break;
      case 'white':
        text  = 'white';
        break;
      default:
        text  = 'primary';
    }
    return text;
  };

	//console.log(' ');
 // console.log(' ');
	//console.log(' ');
	//console.log('-- cta-multiple --');
	//console.log('componentData: ', componentData);
</script>